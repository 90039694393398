import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { Image } from "react-bootstrap";

interface AppPayComponentProps {
  width: string;
  image: string;
  paymentMethod: string;
  paymentName: string;
  onClick: Function;
}

const AppPayComponent: React.FC<AppPayComponentProps> = (props: AppPayComponentProps) => {
  return (
    <PaymentMethodWrapper onClick={() => props.onClick(props.paymentMethod)}>
      <PaymentMethod>
        <Image width={props.width} src={props.image} alt={props.paymentMethod} fluid />
        <Text>
          <Trans>{props.paymentName}</Trans>
        </Text>
      </PaymentMethod>
    </PaymentMethodWrapper>
  );
}

const PaymentMethodWrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 0px 15px 30px;
  margin: 30px 0px;
  p {
    margin: 0px;
  }
  :hover {
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  @media (max-width: 768px) {
    border: 1px solid #333333;
    border-radius: 20px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 500;
  padding-left: 30px;
  @media (max-width: 410px) {
    font-weight: 700;
    font-size: 15px;
  }
  @media (max-width: 390px) {
    font-weight: 700;
    font-size: 14px;
  }
  @media (max-width: 370px) {
    font-weight: 700;
    font-size: 12px;
  }
`;

export default AppPayComponent;